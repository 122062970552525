import React from 'react';
import { Link } from "react-router-dom";

export default function Home() {
    return <>
        <div className={`container mt-3`}>
            <div className="jumbotron jumbotron-fluid mt-5 mb-5">
                <div className="container">

                <div className={`py-5`}>
                    <h3 className={`pb-3 text-center`}>Welcome To CDR M&E!</h3>
                    <p className="lead text-center">
                    We're a top Mechanical and Electrical company in England, providing cutting-edge solutions for various industries. 
                    </p>

                    <h3 className={`p-3 text-center`}>Why Choose Us</h3>
                    <div className="lead d-grid justify-content-center">
                    <ul className={`list-unstyled text-center`}>
                        <li>Quality Assurance: Our priority is quality, safety, and efficiency. </li>
                        <li>Innovation: We stay updated with industry trends. </li>
                        <li>Client-Centric Approach: Tailored solutions for unique needs. </li>
                        <li>Environmental Responsibility: Commitment to sustainability. </li>
                        <li>Reliability: Meeting deadlines and exceeding expectations.</li>
                    </ul>
                    </div>
                </div>
                </div>
                </div>
                </div>

                <div className={`w-100 bg-danger`}>
                <div className={`py-5 text-center text-white`}>
                    <h3 className={`pb-3`}>Our Expertise</h3>
                    <p className="lead text-center">
                        CDR M&E Services specialises in top-tier mechanical and electrical services, from design to maintenance. Our skilled team handles projects of all sizes. 
                    </p>
                </div>

                </div>

                <div className={`container mt-3`}>
            <div className="jumbotron jumbotron-fluid mt-5 mb-5">
                     <div className="container">
                    <div className={`py-5 text-center`}>
                    <h3 className={`pb-3`}>Industries We Serve</h3>
                    <p className="lead text-center">
                        We serve domestic, commercial, industrial, healthcare, education, and more. Count on us for your mechanical and electrical needs. 
                    </p>
                    </div>
                    </div>
                    <div className={`text-center`}>
                    <a href="mailto:helpdesk@cdrme.co.uk?subject=Enquiry" className={`btn btn-danger`}><strong>GET A QUOTE TODAY</strong></a>
                    </div>
                </div>
                </div>

                <div className={`container mt-3`}>
            <div className="jumbotron jumbotron-fluid mt-5 mb-5">
            <div className={`row`}>
                <div className={`col-sm-12 col-lg-6 p-3`}>
                    <Link className={`p-3 align-self-center text-black text-decoration-none dropdown-item text-uppercase`} to="services/electrical">
                        <div className={`home-card-bg--electricals`}>
                        </div>
                        <p className={`btn btn-block btn-danger btn-lg text-decoration-none`}>
                            <strong>Electrical</strong>
                        </p>
                    </Link>
                </div>
                <div className={`col-sm-12 col-lg-6 p-3`}>
                    <Link className={`p-3 align-self-center text-black text-decoration-none dropdown-item text-uppercase`} to="services/mechanical">
                        <div className={`home-card-bg--mechanical`}>
                        </div>
                        <p className={`btn btn-block btn-danger btn-lg text-decoration-none`}>
                            <strong>Mechanical</strong>
                        </p>
                    </Link>
                </div>
                </div>
                </div>
            </div>
    </>;
}