import { React, useState, useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import {
    HashRouter,
    Routes,
    Route,
    useLocation
} from "react-router-dom";

export default function Hero() {
    const matches = useMediaQuery('(min-width: 992px)');

    const location = useLocation();

    const [path, setPath] = useState(location.pathname);

    useEffect(() => {
        setPath(location.pathname);
    }, [location])

    let pathName;

    switch (path) {
    case '/':
        pathName = 'CDR M&E Services LTD';
        break;
    case '/about':
        pathName = 'About';
        break;
    case '/services/electrical':
        pathName = 'Electrical';
        break;
    case '/services/mechanical':
        pathName = 'Mechanical';
        break;
    case '/contact':
        pathName = 'Contact Us';
        break;
    }

    if (matches) {
        return <div className={`background-hero`}>
        <div className={`background-hero-gradient d-flex`}>
            <div className={`container h-100`}>
                <div className={`row h-100`}>
                    <div className={`col p-6 text-center h-100 d-flex align-items-center justify-content-center w-100 flex-column`}>
                        <h1 className={`text-white h1-large w-100`}>{pathName}</h1><br/><br/>
                        <h2 className={`text-white h3-large w-100`}>Mechanical & Electrical Solutions in England</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
} else {
    return <div className={`background-hero`}>
        <div className={`background-hero-gradient d-flex`}>
            <div className={`container h-100`}>
                <div className={`row h-100`}>
                    <div className={`col p-6 text-center h-100 d-flex align-items-center justify-content-center w-100 flex-column`}>
                        <h1 className={`text-white h1-small w-100`}>{pathName}</h1><br/><br/>
                        <h2 className={`text-white h3-small w-100`}>Mechanical & Electrical Solutions in England</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
} 
}