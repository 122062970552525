import React from 'react';

export default function Maintaining() {
    return <div>
        <div className={`container mt-3 mb-3`}>
            <div className="jumbotron jumbotron-fluid mt-5 mb-5">
                <div className="container">
                    <p className="lead">
                        Engineers on hand to help with install, maintenance and certification within the areas of plumbing, heating, gas and oil.
                    </p>
                </div>
            </div>

            <div className={`row`}>
                <div className={`col-sm-12 col-lg-6`}>
                    {/* <div className={`maintenance-first-image`}> */}
                    {/* </div> */}
                </div>
                <div className={`col-sm-12 col-lg-6 mt-2 mt-lg-0`}>
                    
                </div>
            </div>
        </div>
    </div>;
}