import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navigation from './components/Navigation.jsx';
import Home from './components/Webpages/Home.jsx';
import About from './components/Webpages/About.jsx';
import Services from './components/Webpages/Services.jsx';
import Mechanical from './components/Webpages/Mechanical.jsx';
import Electrical from './components/Webpages/Electrical.jsx';
import Contact from './components/Webpages/Contact.jsx';
import Hero from './components/Hero.jsx';
import Footer from './components/Footer.jsx';
import {
    HashRouter,
    Routes,
    Route,
    useLocation
} from "react-router-dom";

function App() {

  return (
      <div className="App">
          <HashRouter>
              <Navigation />
              <Hero />
              <Routes>
                  <Route path="/" element={<Home />}>Home</Route>
                  <Route path="about" element={<About />}>About</Route>
                  <Route path="services/*">
                      <Route path="electrical" element={<Electrical />}/>
                      <Route path="mechanical" element={<Mechanical />}/>
                  </Route>
                  <Route path="contact" element={<Contact />}>Contact</Route>
              </Routes>
              <Footer />
          </HashRouter>
    </div>
  );
}

export default App;
