import React from 'react';

export default function Footer() {

    return <>
        <footer className={`mt-3 w-100 border-top`}>
            <div className={`container`}>
                <div className={`row mt-3 text-center`}>
                    {/* <div className={`col d-flex`}> */}
                    {/*     <div className={`footer-img--NAPIT col`}></div> */}
                    {/*     <div className={`footer-img--citb col`}></div> */}
                    {/*     <div className={`footer-img--environment-agency col`}></div> */}
                    {/* </div> */}
                    {/* <div className={`border-top mt-3 mb-3`}></div> */}
                    <div className={`row`}>
                        <div className={`col text-center`}>
                            <p>&copy; CDR M&E Services Ltd {(new Date().getFullYear())}</p>
                            <p>Created by <a href={`https://jonathanadamwall.com`} target={`_blank`} className={`text-decoration-none text-black`}>Jonathan Wall</a></p>
                        </div>
                        </div>
                </div>
            </div>
        </footer>
        </>
}