import React from 'react';

export default function Cleaning() {

    return <div>
        <div className={`container mt-3 mb-3`}>
            <div className="jumbotron jumbotron-fluid mt-5 mb-5">
                <div className="container">
                    <p className="lead">
                        Provide all types of certifications including but not limited to EICRs, PAT, Lightning Protection, EV, Solar 
Provide install for all lighting, power, fire and security services.
Provide designs for lighting, power, fire and security services.
Provide planned and reactive maintenance for lighting, power, fire and security services.
                    </p>
                </div>
            </div>

            <div className={`row`}>
                <div className={`col-sm-12 col-lg-6`}>
                    {/* <div className={`cleaning-first-image`}> */}
                    {/* </div> */}
                </div>
                <div className={`col-sm-12 col-lg-6 mt-2 mt-lg-0`}>
                    <p>
                    </p>
                </div>
            </div>
        </div>
    </div>;
}