import React, { useState } from 'react';
import { Navbar, Nav, Container, Offcanvas, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'usehooks-ts';

export default function Navigation() {
    const [expanded, setExpanded] = useState(false);

    const matches = useMediaQuery('(min-width: 992px)');

    var sectionStyle = {
        display: "flex",
        alignItems: "center",
        top: 0,
        position: "absolute" 
    };

    function handleNavigation() {
        if (matches) {
            return (
                <Navbar variant="light" className={`bg-transparent`}>
                    <Nav className="navigation-height">
                        <Link className={`p-3 align-self-center text-white text-decoration-none text-uppercase`} to="/"><div className={`logo-img`}></div></Link>
                        <Link className={`p-3 align-self-center text-white text-decoration-none text-uppercase`} to="/">Home</Link>
                        <Link className={`p-3 align-self-center text-white text-decoration-none text-uppercase`} to="about">About</Link>
                        <NavDropdown title="Services" id="basic-nav-dropdown" className={`p-3 align-self-center text-white text-decoration-none bg-transparent text-uppercase`}>
                            <Link className={`p-3 align-self-center text-black text-decoration-none dropdown-item text-uppercase`} to="services/electrical" onClick={() => setExpanded(0)}>Electrical</Link>
                            <Link className={`p-3 align-self-center text-black text-decoration-none dropdown-item text-uppercase`} to="services/mechanical" onClick={() => setExpanded(0)}>Mechanical</Link>
                        </NavDropdown>
                           
                        <Link className={`p-3 align-self-center text-white text-decoration-none text-uppercase`} to="contact">Contact</Link>
                    </Nav>
                </Navbar>
            );
        } else {
            return (
                <Navbar className={`w-100`} style={sectionStyle} expand={false} expanded={expanded} variant={`dark`}>
                    <Container fluid>
                        <Navbar.Brand href="/"><div className={`logo-img`}></div></Navbar.Brand>
                        <Navbar.Toggle className={`dark-toggle`} aria-controls="offcanvasNavbar" onClick={() => setExpanded(expanded ? false : "expanded")} />
                        <Navbar.Offcanvas
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="end" className={`bg-danger`}>
                            <Offcanvas.Header closeButton onClick={() => setExpanded(expanded ? false : "expanded")}>
                                <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Link className={`p-3 text-white text-decoration-none text-uppercase`} to="/" onClick={() => setExpanded(0)} to="/">Home</Link>
                                    <Link className={`p-3 text-white text-decoration-none text-uppercase`} to="/about" onClick={() => setExpanded(0)} to="about">About</Link>
                                    <NavDropdown title="Services" id="basic-nav-dropdown" className={`p-3 text-white text-decoration-none bg-transparent text-uppercase`}>
                                        <Link className={`p-3 align-self-center text-black text-decoration-none dropdown-item text-uppercase`} to="services/electrical" onClick={() => setExpanded(0)}>Electrical</Link>
                                        <Link className={`p-3 align-self-center text-black text-decoration-none dropdown-item text-uppercase`} to="services/mechanical" onClick={() => setExpanded(0)}>Mechanical</Link>
                                    </NavDropdown>
                                    <Link className={`p-3 text-white text-decoration-none text-uppercase`} to="/contact" onClick={() => setExpanded(0)} to="contact">Contact</Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            );
        }
    }

    return (
        <>
            {handleNavigation()}
        </>
    );
}