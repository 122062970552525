import React from 'react';

export default function Contact() {
    return <div className={`container`}>
        <div className={`row m-0 pt-3`}>
            <div className={`col-sm-12 col-lg-6 d-flex flex-column text-center text-md-start justify-content-center`}>
                <p>10 Woodstock Court</p>
                <p>Stockton-on-Tees</p>
                <p> North Yorkshire</p>
                <p> TS18 3BL</p>
                <p>T: <a href={`tel:+447916766160`}>07916 766160</a></p>
                <p>E: <a href="mailto:helpdesk@cdrme.co.uk?subject=Enquiry">helpdesk@cdrme.co.uk</a></p>
            </div>
            <div className={`col-sm-12 col-lg-6`}>
                <iframe title={`iframe`} src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d578.5356651840423!2d-1.3115525349345762!3d54.5486105706011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1707573868671!5m2!1sen!2suk" width="100%" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    </div>;
}