import React from 'react';

export default function About() {
    return <div>
        <div className={`container mt-3 mb-3`}>
            <div className="jumbotron jumbotron-fluid mt-5 mb-5">
                <div className="container">
                    <p className="lead">
                        Founded November 2021, we specialise in top-tier mechanical and electrical services, from design to maintenance. Our skilled team handles projects of all sizes. 
                    </p>
                    <p>
                        We cover the whole of the North East of England and service businesses in Leeds, York, Teesside, Durham and Newcastle.
                    </p>
                </div>
            </div>
            <div className={`row`}>
                <div className={`col-sm-12 col-lg-4`}>
                    <div className={`about-first-image`}>
                    </div>
                </div>
                <div className={`col-sm-12 col-lg-8 mt-2 mt-lg-0`}>
                    <p>
                        Consistently striving to deliver the best service possible and finding ways to deliver results in an efficient, cost effective and skilled way. Get in touch to find out how I can help your property become more efficient and stay in top shape. 
                    </p>
                    <p>
                        <strong>Connor Hannah</strong> - <small>Managing Director</small>
                    </p>
                </div>
            </div>
            </div>
    </div>;
}